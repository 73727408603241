<template>
  <div class="searchResult">
    <my-banner :banner="banner" />
    <my-notice ref="notice" :noticeTitle="noticeTitle" />
  </div>
</template>

<script>
import { queryPhotoManageList } from "@/api/api.js";
export default {
  name: "journalism",
  data() {
    return {
      banner: "",
      noticeTitle: "",
    };
  },
  created() {
    queryPhotoManageList({
      photoPage: "新闻动态",
    }).then((res) => {
      this.banner = res.data.rows[0].photoPicture;
    });
  },
  watch: {
    "$route.query.noticeTitle": {
      handler(val) {
        this.noticeTitle = val;
      },
      immediate: true,
    },
  },
};
</script>
